import React, { useState, useEffect } from "react";
import './index.less';
import Header from "../../components/header";
import MainImg from '../../assets/yanxuan/thirdF/main.png';
import MainImg2 from '../../assets/yanxuan/thirdF/main2.png';
import MainImg3 from '../../assets/yanxuan/thirdF/main3.png';
import MainImg5 from '../../assets/yanxuan/thirdF/main5.png';
import MainImg6 from '../../assets/yanxuan/thirdF/main6.png';
import MainImg7 from '../../assets/yanxuan/thirdF/main7.png';
import Info from '../../assets/yanxuan/thirdF/info.png';
import Title from '../../assets/yanxuan/thirdF/title.png';
import BottomView from "../../components/bottomView";
import ToTop from "../../components/toTop";
import VideoPlayer from "./player";
import Paozhi from '../../assets/video/paozhi.mp4';
const ThirdF = () => {
  // 中医药传承创新基地
  const [playVideo, setPlayVideo] = useState(false);
  return <div className="Digital" style={{ backgroudColor: '#fff' }}>
    <Header index='digital' bc='#fff' />
    <img src={MainImg} style={{ width: '100%' }} />
    <img src={MainImg2} style={{ width: '100%' }} />
    <div style={{ width: '100%', paddingLeft: "60px", paddingRight: '60px' }} >
      <img src={MainImg3} style={{ width: '100%' }} />
      <img src={Title} style={{ width: '100%' }} />
      <div className="vidT">
        <div style={{ marginLeft: '30px', marginBottom: '30px' }}>
          <VideoPlayer toPlay={playVideo} videoSource={Paozhi} />
        </div>
        <img className="udh" src={Info} />
      </div>
    </div>

    <div style={{ width: '100%', paddingLeft: "60px", paddingRight: '60px' }} >
      <img src={MainImg5} style={{ width: '100%' }} />
      <img src={MainImg6} style={{ width: '100%' }} />

    </div>
    <img src={MainImg7} style={{ width: '100%' }} />
    <BottomView loca='virtual' />
    <ToTop red />
  </div>
}
export default ThirdF;







