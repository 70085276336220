import React, { useState, useEffect } from "react";
import './index.less';
import Header from "../../components/header";
import MainImg from '../../assets/yanxuan/qiyezixun/main.png';
import BottomView from "../../components/bottomView";
import $$ from "cmn-utils";
import { getDetail } from './service';

import ToTop from "../../components/toTop";


const Message = () => {
  const [ahtml, setAhtml] = useState('');
  const [titleK, setTitleK] = useState('');
  const [timeK, setTimeK] = useState('');
  const GetQueryString = () => {

    const url_string = window.location.href; // window.location.href
    var params = url_string.split('?')[1];
    let id = params.split('=')[1]
    return id;

  }
  useEffect(() => {
    console.log(ahtml)
  }, [ahtml])
  useEffect(() => {
    let a = GetQueryString()
    console.log(a)
    getDetail({ id: a }).then((res) => {
      console.log(res)
      setAhtml(res.data.detail.content);
      setTitleK(res.data.detail.title);
      setTimeK(res.data.detail.publish_at);
    })
  }, [])
  return <div className="aboutUs">
    <Header />
    <img src={MainImg} style={{ width: '100%' }} />
    <div className="ACon">
      <div className="titileK">{titleK}</div>
      <div className="timeC">发布时间：{timeK}</div>
      <div className="articlContent" dangerouslySetInnerHTML={{ __html: ahtml }}></div>
    </div>

    <BottomView loca='About' />
    <ToTop red />
  </div>
}

export default Message;