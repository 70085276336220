import React from "react";
import './index.less';
import Header from "../../components/header";
import BottomView from "../../components/bottomView";
import MainImg from '../../assets/yanxuan/yewufanwei/main.png';
import MainImg2 from '../../assets/yanxuan/yewufanwei/main2.png';
import MainImg3 from '../../assets/yanxuan/yewufanwei/main3.png';
import MainImg4 from '../../assets/yanxuan/yewufanwei/main4.png';
import ToTop from "../../components/toTop";
// import AiAssistant from "../../components/aiAssistant";

const Business = () => {
  // 业务范围
  return <div className="aboutUs">
    <Header index='aboutUs' />
    <img src={MainImg} style={{width:'100%'}} />
    <img src={MainImg2} style={{width:'100%'}} />
    <img src={MainImg3} style={{width:'100%'}} />
    <img src={MainImg4} style={{width:'100%'}} />
    {/* <AiAssistant/> */}
    <BottomView loca='About' />
    <ToTop red />
  </div>
}

export default Business;