import React from "react";
import './index.less';
import $$ from "cmn-utils";
import { useNavigate } from "react-router-dom";

const NewsItem = (props) => {
  let { data } = props;
  const navigate = useNavigate();
  let d = data.publish_at.substring(0, 10).split("-")
  const checkDetail = (data1) => {
    console.log(data1)
    navigate(`/messageDetail?id=${data1}`)
  }
  return <div className="newsItem2" onClick={()=>{checkDetail(data.id)}}>
    <div className="big">
      <div className="info">
        <div className="ooo">
          <div className="newsTitle">{data.title}</div>
          <div className="date1">
            {d[0]}/{d[1]}/{d[2]}
          </div>
        </div>

        <div className="newsDesc">{data.desc}</div>
      </div>
      <div className="dateCon">

        <div className="date2"></div>
      </div>
    </div>

    <div className="line3"></div>
  </div>
}

export default NewsItem;