import React from "react";
import './index.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
// import './swiper.min.css'
import "swiper/css";
import "swiper/css/pagination";

const SwiperView = (props) => {
  const {imgList,imgH} = props;
  return <div className="swiperCon">
    <Swiper
      modules={[Pagination, Scrollbar, Autoplay, Navigation, A11y]}
      pagination={true}
      // spaceBetween={50}
      // slidesPerView='auto'
      // navigation={{ enabled: true }}
      // Pagination={{ enabled: true, clickable: true, el: '.swiper-pagination' }}
      // scrollbar={{ draggable: true }}
      // onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => {}}
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
      loop
    >
      {
        imgList.map((img,index)=>{
          return <SwiperSlide key={index}><img src={img} style={{height:`${imgH}rem`}}/></SwiperSlide>
        })
      }
      
    </Swiper>
  </div>
}

export default SwiperView;