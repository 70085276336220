import React,{useState} from "react";
import './index.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
// import './swiper.min.css'
import "swiper/css";
import "swiper/css/pagination";
import More from '../../assets/yanxuan/firstF/more.png';

const SwiperView = (props) => {
  const { imgList, imgH } = props;
  const [activeIndex,setActiveIndex] = useState('0');
  const checkThis = (id)=>{
    if(activeIndex==id){
      setActiveIndex('0')
    }else{
      setActiveIndex(id)
    }
  }
  return <div className="swiperCon1">
    <Swiper
      modules={[Pagination, Scrollbar, Autoplay, Navigation, A11y]}
      pagination={false}
      spaceBetween={100}
      slidesPerView='4'
      // navigation={{ enabled: true }}
      // Pagination={{ enabled: true, clickable: true, el: '.swiper-pagination' }}
      // scrollbar={{ draggable: true }}
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        disabledClass: 'disable' // 当导航按钮变为不可用时添加的class,也就是当swiper索引为0时上一张没有prevEl的class类名就会添加一个disable，也就是.swiper-button-prev .disable
      }}
      // onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => { }}
      // autoplay={{
      //   delay: 2000,
      //   disableOnInteraction: false,
      // }}
      loop
    >
      {
        imgList.map((data, index) => {
          console.log(data)
          return <SwiperSlide key={index}>
            <div className="bigBck" onClick={()=>{checkThis(data.id)}}>
              <img src={data.img} className="bigImg"/>
                {
                  activeIndex==data.id?<img src={data.img1} className="litiImg"/>:null
                }
              <div  className={activeIndex==data.id?"infoCon1":"infoCon"}>
                <div className="namec">
                  <div className="namex1">{data.name}</div>
                  <div className="namex2">{data.nameE}</div>
                </div>
                <img src={More} className="more"/>
              </div>
            </div>
          </SwiperSlide>
        })
      }
      <div className="btnCo">
        <div className="swiper-button-prev rot leftS" slot="button-prev">
          <img style={{width:'.5rem',height:'.5rem'}} src={require('../../assets/yanxuan/qiyegaikuang/left.png')} />
        </div>
        <div className="swiper-button-next rat" slot="button-next">
          <img style={{width:'.5rem',height:'.5rem'}} src={require('../../assets/yanxuan/qiyegaikuang/right.png')} />
        </div>
      </div>
    </Swiper>


  </div>
}

export default SwiperView;