import React from 'react';
import PageLoading from './components/Loading/PageLoading';
import { normal } from './components/Notification';
import { isFunction } from 'cmn-utils/lib/utils';


// 系统通知, 定义使用什么风格的通知，normal或antdNotice
const notice = normal;
/**
 * 应用配置 如请求格式，反回格式，异常处理方式，分页格式等
 */
export const ImagePrefix = 'https://'
// 正式环境
export const baseapi = process.env.NODE_ENV === 'production'?'https://www.yaoduyanxuan.com.cn':'';//api域名
export const baseurl = process.env.NODE_ENV === 'production'?'https://www.yaoduyanxuan.com.cn':'https://uat.go.hugelink.cn';//前端域名
// uat
// export const baseapi = process.env.NODE_ENV === 'production'?'https://uat.go.hugelink.cn':'/api';//api域名
// export const baseurl = process.env.NODE_ENV === 'production'?'https://uat.go.hugelink.cn':'https://uat.go.hugelink.cn';//前端域名
export default {
  /**
   * HTML的title模板
   */
  htmlTitle: '药都严选 - {title}',

  /**
   * 系统通知
   */
  notice,

  // 异步请求配置
  request: {
    // prefix: '',
    prefix: process.env.NODE_ENV === 'production' ? '' : '/api',
    // 每次请求头部都会带着这些参数
    withHeaders: () => ({
      // 'Content-Length':2555755,
      // 'Transfer-Encoding':'chunked',
      'X-Terminal-Flag': 'Web',
      // 'X-Web-Token': $$.getStore("X-Manage-Token"),
      // 'X-Username': store.getStore("applicationStore_useName",''),
      // 'X-Terminal-Flag': 'cc_web',
      // 'Host': 'vargo',
    }),

    /**
     * 因为modelEnhance需要知道服务器反回的数据，
     * 什么样的是成功，什么样的是失败，如
     * {status: true, data: ...} // 代表成功
     * {status: false, message: ...} // 代表失败
     * 实际中应该通过服务端反回的response中的
     * 成功失败标识来进行区分
     */
    parseResponse: async (response, responseType) => {
      return {
        status: response.status,
        data: isFunction(response && response[responseType]) ? await response[responseType]() : response
      }
    },
    afterResponse: (response) => {
      const { status, data } = response;
      // console.log(status)
      // console.log(data)
      // if(status==401){
      //   message.error(data.msg);
      //     $$.removeStore('applicationStore_userData');
      //     $$.removeStore('X-Manage-Token');
      //     $$.removeStore('applicationStore_useName');
      //     $$.removeStore('service_token');
      //     $$.removeStore('role');
      //     $$.removeStore('corp_no');
      //     $$.removeStore('ad_code');
      //     setTimeout(() => {
      //       window.location.reload();
      //     }, 1500)
      // }
      if (status === 200) {
        // if ((data.result==-1&&data.msg=="您已在其他设备登录")||data.result==401||data.result==4001||data.msg=='登录信息有误') {
        //   message.error(data.msg);
        //   $$.removeStore('applicationStore_userData');
        //   $$.removeStore('X-Manage-Token');
        //   $$.removeStore('applicationStore_useName');
        //   $$.removeStore('service_token');
        //   $$.removeStore('role');
        //   $$.removeStore('corp_no');
        //   $$.removeStore('ad_code');
        //   setTimeout(() => {
        //     window.location.reload();
        //   }, 1500)
        // }
        return data;
      }
    },
    errorHandle: err => {
      //请求错误全局拦截
      if (err.name === 'RequestError') {
        // notice.error(err.text || err.message);
        if (err.response) {
          err.response.json().then(r => {
            // message.error(r.msg || "操作失败");
          })
        } else {
          // message.error(err.message || "操作失败");
        }
      }
    }
  },

  // 全局异常
  exception: {
    global: (err, dispatch) => {
      const errName = err.name;
      // RequestError为拦截请求异常
      if (errName === 'RequestError') {
        // message.error(err.message);
        console.error(err);
      } else {
        console.error(err);
      }
    },
  },

  // 分页助手
  pageHelper: {
    // 格式化要发送到后端的数据
    requestFormat: pageInfo => {
      const { pageNum, pageSize, filters, sorts } = pageInfo;
      return {
        currentPage: pageNum,
        showCount: pageSize,
        sortMap: sorts,
        paramMap: filters
      };
    },

    // 格式化从后端反回的数据
    responseFormat: resp => {
      const {
        currentPage,
        showCount,
        totalResult,
        dataList,
        totalPage
      } = resp.data;
      return {
        pageNum: currentPage,
        pageSize: showCount,
        total: totalResult,
        totalPages: totalPage,
        list: dataList
      };
    }
  },

  // 路由加载效果
  router: {
    loading: <PageLoading loading />
  },

  /**
   * 模拟数据时包装反回数据
   * 因为，后端反回数据时一般都会在外边包装一层状态信息
   * 如成功时：
   * {
   *   status: true,
   *   data: responseData
   * }
   * 或出错时：
   * {
   *   status: false,
   *   code: 500,
   *   message: '用户名或密码错误'
   * }
   * 这里就是配置这两个函数，为了我们模拟数据时可以少写几行代码的 orz...
   */
  mock: {
    toSuccess: response => ({
      status: true,
      data: response
    }),

    toError: message => ({
      status: false,
      message: message
    })
  }
};
