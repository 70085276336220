import React, { useState, useEffect } from "react";
import './index.less';
import Header from "../../components/header";
import MainImg from '../../assets/yanxuan/firstF/main.png';
import MainImg2 from '../../assets/yanxuan/firstF/main2.png';
import MainImg3 from '../../assets/yanxuan/firstF/main3.png';
import MainImg4 from '../../assets/yanxuan/firstF/main4.png';
import MainImg5 from '../../assets/yanxuan/firstF/main5.png';
import MainImg6 from '../../assets/yanxuan/firstF/main6.png';
import MainImg7 from '../../assets/yanxuan/firstF/main7.png';
import MainImg8 from '../../assets/yanxuan/firstF/main8.png';
import MainImg9 from '../../assets/yanxuan/firstF/main9.png';
import MainImg12 from '../../assets/yanxuan/firstF/main12.png';
import MainImg13 from '../../assets/yanxuan/firstF/main13.png';
import BottomView from "../../components/bottomView";
import ToTop from "../../components/toTop";
import SwiperView from "../../components/swiper3";
import BlockTitle from "../../components/blockTitle";

import haoliancheng from '../../assets/yanxuan/firstF/haoliancheng.png';
import haoliancheng1 from '../../assets/yanxuan/firstF/haoliancheng1.png';
import geshaoqin from '../../assets/yanxuan/firstF/geshaoqin.png';
import geshaoqin1 from '../../assets/yanxuan/firstF/geshaoqin1.png';
import sunchengdong from '../../assets/yanxuan/firstF/sunchengdong.png';
import sunchengdong1 from '../../assets/yanxuan/firstF/sunchengdong1.png';
import xuzhenguo from '../../assets/yanxuan/firstF/xuzhenguo.png';
import xuzhenguo1 from '../../assets/yanxuan/firstF/xuzhenguo1.png';
import renxu from '../../assets/yanxuan/firstF/renxu.png';
import renxu1 from '../../assets/yanxuan/firstF/renxu1.png';
import yudameng from '../../assets/yanxuan/firstF/yudameng.png';
import yudameng1 from '../../assets/yanxuan/firstF/yudameng1.png';
import cuiqingli from '../../assets/yanxuan/firstF/cuiqingli.png';
import cuiqingli1 from '../../assets/yanxuan/firstF/cuiqingli1.png';
import wangyuguang from '../../assets/yanxuan/firstF/wangyuguang.png';
import wangyuguang1 from '../../assets/yanxuan/firstF/wangyuguang1.png';

const FirstF = () => {
  // 中医药传承创新基地
  const [active, setActive] = useState('0');
  const changeActive = (i) => {
    if (active == i) {
      setActive('0')
    } else {
      setActive(i)
    }
  }
  const dockerInfo = [{
    id:'1',
    name: '郝连成',
    nameE: 'HAO LIAN CHENG',
    img: haoliancheng,
    img1: haoliancheng1
  }, {
    id:'2',
    name: '葛少钦',
    nameE: 'GE SHAO QIN',
    img: geshaoqin,
    img1: geshaoqin1
  }, {
    id:'3',
    name: '孙成栋',
    nameE: 'SUN CHENG DONG',
    img: sunchengdong,
    img1: sunchengdong1
  }, {
    id:'4',
    name: '徐振国',
    nameE: 'XV ZHEN GUO',
    img: xuzhenguo,
    img1: xuzhenguo1
  }, {id:'5',
    name: '任旭',
    nameE: 'REN XU',
    img: renxu,
    img1: renxu1
  }, {
    id:'6',
    name: '于大猛',
    nameE: 'YU DA MENG',
    img: yudameng,
    img1: yudameng1
  }, {
    id:'7',
    name: '崔庆利',
    nameE: 'CUI QING LI',
    img: cuiqingli,
    img1: cuiqingli1
  }, {
    id:'8', 
    name: '王玉光',
    nameE: 'WANG YU GUANG',
    img: wangyuguang,
    img1: wangyuguang1
  }]
  return <div className="Digital" style={{ backgroudColor: '#fff' }}>
    <Header index='digital' bc='#fff' />
    <img src={MainImg} style={{ width: '100%' }} />
    <img src={MainImg2} style={{ width: '100%',marginTop:'30px' }} />
    <img src={MainImg3} style={{ width: '100%' }} />
    <img src={MainImg4} style={{ width: '100%' }} />
    <img src={MainImg5} style={{ width: '100%' }} />
    <img src={MainImg6} style={{ width: '100%' }} />
    <img src={MainImg7} style={{ width: '100%' }} />
    <img src={MainImg8} style={{ width: '100%' }} />
    <img src={MainImg9} style={{ width: '100%' }} />
    <img src={MainImg12} style={{ width: '100%' }} />
    <img src={MainImg13} style={{ width: '100%' }} />
    <div className="guoyiguan">
      <BlockTitle title='大医精诚国医名医工作室' tieleEng='PHYSICIAN  TEAM' len={20} />
      <SwiperView imgList={dockerInfo} imgH={5}/>
    </div>

    {/* <img src={MainImg5} style={{ width: '100%' }} /> */}
    <BottomView loca='virtual' />
    <ToTop red />
  </div>
}
export default FirstF;







