import React, { useState, useEffect } from "react";
import './index.less';
import Header from "../../components/header";
import SwiperView from "../../components/swiper";
// import AiAssistant from "../../components/aiAssistant";
import swiperImg from '../../assets/yanxuan/qiyegaikuang/swiper1.png'
import context from '../../assets/yanxuan/qiyegaikuang/context.png'
import qiyerongyu from '../../assets/yanxuan/qiyegaikuang/qiyerongyu.png'
import qiyefengcai from '../../assets/yanxuan/qiyegaikuang/qiyefengcai.png'
import BottomView from "../../components/bottomView";
import ToTop from "../../components/toTop";
import VideoPlayer from '../../components/player';
// import yanxuanVideo from '../../assets/video/yanxuan.mp4';
import SwiperView2 from '../../components/swiper2';
import rongyu1 from '../../assets/yanxuan/qiyegaikuang/rongyu1.png';
import rongyu2 from '../../assets/yanxuan/qiyegaikuang/rongyu2.png';
import rongyu3 from '../../assets/yanxuan/qiyegaikuang/rongyu3.png';
import rongyu4 from '../../assets/yanxuan/qiyegaikuang/rongyu4.png';

const AboutCorporation = () => {
  const [playVideo, setPlayVideo] = useState(false);
  const [active, setActive] = useState(true);
  useEffect(() => {
    document.addEventListener('scroll', handleScroll, true);
  }, [])
  const handleScroll = () => {
    // let docItem = document.getElementById('maodian2');
    // let hei = docItem.offsetTop
    const scrollTop = window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop || 0
    // scrollTop 为距离滚动条顶部高度
    // scrollHeight 为整个文档高度
    // 我们设定当滚动的距离大于 200 时，显示 【返回顶部】按钮
    if (window.scrollY - 300 < scrollTop) {
      setPlayVideo(true)
    }
  }
  useEffect(() => {

  }, [playVideo])
  return <div className="EnterTainment">
    <Header index='enterTainment' bc='#FFF' />
    <SwiperView imgList={[swiperImg, swiperImg]} imgH={7.99} />
    <div className="mainContentDiv">
      <div className="top">
        <div className="text1">关于我们</div>
        <div className="text2">药都严选健康产业集团</div>
        <div className="bottomLine"></div>
      </div>
      <div className="top2">
        <div className="top2Left">
          {/* //todo:此处为外部视频链接 */}
          <VideoPlayer toPlay={playVideo} videoSource='https://www.yaoduyanxuan.com.cn/webvideo/static/media/yanxuan.f38faef48e67a032dbde.mp4' />
        </div>
        <div className="top2Right">
          {/* <div className="top2Righttop">
            <div onClick={() => { setActive(true) }} className={active ? 'active' : 'noActive'}>关于药都严选</div>
            <div onClick={() => { setActive(false) }} className={!active ? 'active' : 'noActive'}>关于药都-安国</div>
          </div> */}
          <img src={context} style={{ width: '100%' }} />
        </div>
      </div>
    </div>
    <img src={qiyerongyu} style={{ width: '100%' }} />
    <div className="qiyerongyuCon">
      <div className="qiyerySwip">
        <SwiperView2 imgList={[rongyu1, rongyu2, rongyu3, rongyu4]} />
      </div>
    </div>
    <img src={qiyefengcai} style={{ width: '100%' }} />
    {/* <AiAssistant /> */}
    <BottomView loca='platform' />
    <ToTop />


  </div>
}

export default AboutCorporation;