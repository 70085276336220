import React,{useState,useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Logo from '../../assets/yanxuan/logo.png';
import './index.less'

const Header = (props) => {
  const { index, bc } = props;
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const [isPhone, setIsPhone] = useState(true);
  useEffect(()=>{
    let a = test();
    if(a){
      setIsPhone(true);
    }else{
      setIsPhone(false);
    }
    
  },[])
  useEffect(()=>{
    // console.log('是否是电话:',isPhone)
  },[isPhone])
  const test = ()=>{
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    return flag;
  }
  return <div className="headerCon" style={bc ? { backgroundColor: bc } : null}>
    <img alt="" src={Logo} className='logo' />
    <div className="right">
      {
        show ? <div className="menu">
          <div style={isPhone?{fontSize:'0.38rem',height:'0.75rem',lineHeight:'0.75rem'}:null} onClick={() => { navigate('/aboutAg') }} className={index === 'index' ? "menuItemActive" : "menuItem"}>千年药都</div>
          <div style={isPhone?{fontSize:'0.38rem',height:'0.75rem',lineHeight:'0.75rem'}:null} onClick={() => { navigate('/') }} className={index === 'enterTainment' ? "menuItemActive" : "menuItem"}>企业概况</div>
          <div style={isPhone?{fontSize:'0.38rem',height:'0.75rem',lineHeight:'0.75rem'}:null} onClick={() => { navigate('/structure') }} className={index === 'platForm' ? "menuItemActive" : "menuItem"}>企业架构</div>
          <div style={isPhone?{fontSize:'0.38rem',height:'0.75rem',lineHeight:'0.75rem'}:null} onClick={() => { navigate('/aboutBase') }} className={index === 'digital' ? "menuItemActive" : "menuItem"}>中医药传承创新基地</div>
          <div style={isPhone?{fontSize:'0.38rem',height:'0.75rem',lineHeight:'0.75rem'}:null} onClick={() => { navigate('/productCenter') }} className={index === 'game' ? "menuItemActive" : "menuItem"}>产品中心</div>
          <div style={isPhone?{fontSize:'0.38rem',height:'0.75rem',lineHeight:'0.75rem'}:null} onClick={() => { navigate('/message') }} className={index === 'zixun' ? "menuItemActive" : "menuItem"}>企业资讯</div>
          <div style={isPhone?{fontSize:'0.38rem',height:'0.75rem',lineHeight:'0.75rem'}:null} onClick={() => { navigate('/business') }} className={index === 'aboutUs' ? "menuItemActive" : "menuItem"}>业务范围</div>
          <div style={isPhone?{fontSize:'0.38rem',height:'0.75rem',lineHeight:'0.75rem'}:null} onClick={() => { navigate('/contact') }} className={index === 'contact' ? "menuItemActive" : "menuItem"}>联系我们</div>
          <div style={isPhone?{fontSize:'0.38rem',height:'0.75rem',lineHeight:'0.75rem'}:null} onClick={() => { navigate('/cert') }} className={index === 'cert' ? "menuItemActive" : "menuItem"}>证书查询</div>
        </div> : null
      }

      {/* <img alt="" src={index==='platForm'?More1:More} className='more' onClick={() => { setShow(!show) }} /> */}
    </div>
  </div>
}

export default Header