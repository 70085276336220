import React from "react";
import './index.less';

import Header from "../../components/header";
import Mainimg from '../../assets/yanxuan/chanpinzhongxin/main.png'
import Mainimg2 from '../../assets/yanxuan/chanpinzhongxin/main2.png'
import Mainimg3 from '../../assets/yanxuan/chanpinzhongxin/main3.png'
import BottomView from '../../components/bottomView';
import ToTop from "../../components/toTop";
// import AiAssistant from "../../components/aiAssistant";
const ProductCenter = () => {
  // 产品中心
  return <div className="game">
    <Header index='game' bc='#fff' />
    <img src={Mainimg} style={{ width: '100%' }} />
    <img src={Mainimg2} style={{ width: '100%' }} />
    <div className="productList">
      <a className="chanpinLink" target="_bind" href="https://shop106535771.m.youzan.com/wscshop/showcase/homepage?kdt_id=106343603&sub_kdt_id=106343603">
        <img className="chanpin" src={require('../../assets/yanxuan/chanpinzhongxin/chanpin1.png')} />
      </a>
      <a className="chanpinLink" target="_bind" href="https://shop106535771.m.youzan.com/wscshop/showcase/homepage?kdt_id=106343603&sub_kdt_id=106343603">
        <img className="chanpin" src={require('../../assets/yanxuan/chanpinzhongxin/chanpin2.png')} />
      </a>
      <a className="chanpinLink" target="_bind" href="https://shop106535771.m.youzan.com/wscshop/showcase/homepage?kdt_id=106343603&sub_kdt_id=106343603">
        <img className="chanpin" src={require('../../assets/yanxuan/chanpinzhongxin/chanpin3.png')} />
      </a>
      <a className="chanpinLink" target="_bind" href="https://shop106535771.m.youzan.com/wscshop/showcase/homepage?kdt_id=106343603&sub_kdt_id=106343603">
        <img className="chanpin" src={require('../../assets/yanxuan/chanpinzhongxin/chanpin4.png')} />
      </a>

    </div>
    <img src={Mainimg3} style={{ width: '100%' }} />
    {/* <AiAssistant/> */}
    <BottomView loca='Game' />
    <ToTop />
  </div>

}




export default ProductCenter;