import React,{useState, useEffect} from "react";
import './index.less';
import Header from "../../components/header";
import SwiperView from "../../components/swiper";
import swiper11 from '../../assets/yanxuan/qiannianyaodu/swiper11.png'
import swiper21 from '../../assets/yanxuan/qiannianyaodu/swiper11.png'

import BottomView from "../../components/bottomView";
import ToTop from "../../components/toTop";
import IndexBanner from '../../assets/yanxuan/qiannianyaodu/mainContent.png'
// import AiAssistant from "../../components/aiAssistant";
import { useNavigate } from "react-router-dom";
const AboutAg = (props) => {
  const [playVideo,setPlayVideo]=useState(false);
  const navigate = useNavigate();
  useEffect(()=>{
    document.addEventListener('scroll', handleScroll, true);
  },[])
  const handleScroll = () => {
    console.log('jjjj:::',window.scrollY)
    // let docItem = document.getElementById('maodian1');
    // console.log(docItem)
    // let hei = docItem.offsetTop
    const scrollTop = window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop || 0
    // scrollTop 为距离滚动条顶部高度
    // scrollHeight 为整个文档高度
    // 我们设定当滚动的距离大于 200 时，显示 【返回顶部】按钮
    if(window.scrollY-300<scrollTop){
      setPlayVideo(true)
    }
  }

  const goPage = (navi)=>{
    if(navi){
      window.scrollTo({
        left: 0,
        top: 0,
        // behavior: 'smooth'
      })
      navigate(navi)
    }
  }
  return <div className="indexStyle">
    <Header index='index' />
    <SwiperView imgList={[swiper11, swiper21]} imgH={7.99} />
    <img alt="" src={IndexBanner} style={{width:'100%'}} />
    {/* <AiAssistant /> */}
    <BottomView loca='index'/>
    <ToTop/>
  </div>
}
export default AboutAg;