import React, { useState, useEffect } from "react";
import './index.less';
import imgAi2 from '../../assets/headpic_u9.png';
import closeActive from '../../assets/closeActive.png';

let boxPosition = 50;
const AiAssistant = (props) => {
  const [isZhankai, setIsZhankai] = useState(true);//bar是显示
  const [isActive, setIsActive] = useState(false);//对话是否显示
  const [rightIcon, setRightIcon] = useState(false);//是否展示右侧小球
  const [isMoving, setIsMoving] = useState(false);
  const [boxPosition1, setBoxPosition1] = useState(50);  // 初始位置为 50%
  useEffect(() => {
    setTimeout(() => {
      if (isZhankai) {
        setIsMoving(true)
      }
    }, 1000*60*2);
  }, [])
  // useEffect(() => {
  //   console.log('isZhankai:', isZhankai)
  //   console.log('isActive:', isActive)
  // }, [isZhankai, isActive])
  let timer1 = null
  useEffect(() => {
    if (isMoving) {
      timer1 = setInterval(() => {
        if (boxPosition < 100) {
          boxPosition++
          setBoxPosition1(boxPosition)
        } else {
          clearInterval(timer1);
          boxPosition = 100
          setIsZhankai(false);
          setRightIcon(true);
          setIsMoving(false);
          setBoxPosition1(100);
        }
      }, 20);  // 每 100 毫秒更新一次位置
    }
  }, [isMoving]);

  let timer2 = null;
  const zhankaiFn = () => {
    setIsZhankai(true);
    setRightIcon(false);
    boxPosition = 100;
    timer2 = setInterval(() => {
      if (boxPosition > 50) {
        boxPosition--
        setBoxPosition1(boxPosition)
      } else {
        clearInterval(timer2);
        boxPosition = 50
        setIsZhankai(true);
        setIsMoving(false);
        setBoxPosition1(50);
        setTimeout(() => {
          setIsMoving(true)
        }, 5000);
      }
    }, 20);  // 每 100 毫秒更新一次位置
  }
  const toActive = () => {
    setIsActive(true);
    setIsZhankai(false);
    setRightIcon(false);
  }
  const closeActiveWindow = () => {
    setIsActive(false);//关闭对话窗口
    setBoxPosition1(50);//设置Bar位置
    setIsMoving(false);//关闭出发收起的操作
    setIsZhankai(true);//展开Bar
    setRightIcon(false);//隐藏悬浮球
    setTimeout(() => {//定时2分钟后触发收起操作
        setIsMoving(true)
    }, 1000 * 60);
    
  }
  return <div className="AiAssistant1">
    {
      isActive ? <div className="Aicon">
        <div className="AIconQ">
          <div className="closeCon">
            <div className="closeCon1">
              <div className="zhanwei"></div>
              <div className="zxkf">药都严选在线客服</div>
              <img onClick={closeActiveWindow} className="closeImg" src={closeActive} />
            </div>
          </div>
            <iframe
              src="https://dify.hugelink.cn/chatbot/RKPyF2ttiD0LCFQR"
              style={{ width: '100%', height: '94%', minHeight: 200 }}
              frameBorder="0"
              allow="microphone">
            </iframe>
        </div>
      </div> : null
    }

    {
      isZhankai ? <div onClick={toActive} className='enterBar' style={{ left: `${boxPosition1}%` }}>
        <div className="imgcon">
          <img className="aiImgRobot" src={imgAi2} />
        </div>
        <p className={isZhankai ? 'holderText' : 'holderTextDisplayNone'}>我是AI在线客服，想问点什么呢？</p>
      </div> : null
    }
    {
      rightIcon && !isActive ? <div className="imgconRight" onClick={zhankaiFn}>
        <img className="aiImgRobot" src={imgAi2} />
      </div> : null
    }

  </div>
}
export default AiAssistant;