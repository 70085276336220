import React, { useState, useEffect } from "react";
import './index.less';
import Header from "../../components/header";
import MainImg from '../../assets/yanxuan/secondF/main.png';
import MainImg2 from '../../assets/yanxuan/secondF/main2.png';
import MainImg3 from '../../assets/yanxuan/secondF/main3.png';
import JW1 from '../../assets/yanxuan/secondF/jw1.png';
import JW2 from '../../assets/yanxuan/secondF/jw2.png';
import BottomView from "../../components/bottomView";
import ToTop from "../../components/toTop";

const SecondF = () => {
  // 中医药传承创新基地
  const [active, setActive] = useState('0');
  const [ch,setCh] = useState('1');
   const changeActive = (i) => {
    if (active == i) {
      setActive('0')
    } else {
      setActive(i)
    }
  }
  return <div className="Digital" style={{ backgroudColor: '#fff' }}>
    <Header index='digital' bc='#fff' />
    <img src={MainImg} style={{width:'100%'}} />
    <img src={MainImg2} style={{width:'100%'}} />
    <img src={MainImg3} style={{width:'100%'}} />
    <div className="spaceShow">
      <div className="ts1">空间内部展示</div>
      <div className="ts2">CULTURAL DISPLAY</div>
      <div className="ts5"></div>
      <div className="ts3">
        <div className={ch=='1'?'tsw':'tsd'} onClick={()=>{setCh('1')}}>空间内部展示</div>
        <div className={ch=='2'?'tsw':'tsd'} onClick={()=>{setCh('2')}}>教育培训</div>
        
      </div>
      <div>
        {
          ch=='1'||ch=='3'||ch=='5'?<img style={{width:'100%'}} src={JW1}/>:<img style={{width:'100%'}} src={JW2}/>
        }
      </div>
    </div>
   
    <BottomView loca='virtual' />
    <ToTop red />
  </div>
}
export default SecondF;







