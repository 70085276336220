import React from "react";
import './index.less';
import $$ from "cmn-utils";
import { useNavigate } from "react-router-dom";
const NewsItem = (props) => {
  let { data } = props;
  const navigate = useNavigate();
  let d = data.publish_at.substring(0, 10).split("-")
  const checkDetail = (data1)=>{
  navigate(`/messageDetail?id=${data1}`)
  }
  return <div className="newsItem" onClick={()=>{checkDetail(data.id)}}>
    <div className="info">
      <div className="newsTitle">{data.title}</div>
      <div className="newsDesc">{data.desc}</div>
    </div>
    <div className="dateCon">
      <div className="date1">
        {d[1]}/{d[2]}
      </div>
      <div className="date2">{d[0]}</div>

    </div>
  </div>
}

export default NewsItem;