import React from "react";
import './index.less';
import $$ from "cmn-utils";
import { useNavigate } from "react-router-dom";

const NewsItemImg = (props) => {
  let { data, iurl } = props;
  const navigate = useNavigate();
  let d = data.publish_at.substring(0, 10).split("-")
  const checkDetail = (data1) => {
    navigate(`/messageDetail?id=${data1}`)

  }
  return <div className="newsItem1" onClick={()=>{checkDetail(data.id)}}>
    <img className="newImg" src={`${iurl}${data.image}`} />
    <div className="info">
      <div className="toii">
        <div className="newsTitle">{data.title}</div>
        <div className="date1">
          {d[0]}/{d[1]}/{d[2]}
        </div>
      </div>
      <div className="newsDesc">{data.desc}</div>
    </div>
  </div>
}

export default NewsItemImg;