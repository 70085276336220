import React, { useState } from "react";
import './index.less';

const BlockTitle = (props)=>{
  let {title,tieleEng,len} = props;
  return <div className="blockT">
    <div className="blockTI">{title}</div>
    <div className="blockTitl">{tieleEng}</div>
    <div className="blockLine" style={{width:`${len}px`}}></div>
  </div>
}

export default  BlockTitle;