import React, { useState } from "react";
import './index.less';
import HeaderFFF from "../../components/header/index";
import mainImg from '../../assets/yanxuan/qiyejiagou/main.png';
import title1Img from '../../assets/yanxuan/qiyejiagou/title1.png';
import chanye1Img from '../../assets/yanxuan/qiyejiagou/chanye1.png';
import chanye2Img from '../../assets/yanxuan/qiyejiagou/chanye2.png';
import chanye3Img from '../../assets/yanxuan/qiyejiagou/chanye3.png';
import chanye4Img from '../../assets/yanxuan/qiyejiagou/chanye4.png';
import chanye5Img from '../../assets/yanxuan/qiyejiagou/chanye5.png';
import chanye6Img from '../../assets/yanxuan/qiyejiagou/chanye6.png';
import chanyeDetail1 from '../../assets/yanxuan/qiyejiagou/chanyeDetail1.png';
import chanyeDetail2 from '../../assets/yanxuan/qiyejiagou/chanyeDetail2.png';
import chanyeDetail21 from '../../assets/yanxuan/qiyejiagou/changyeDetail21.png';
import chanyeDetail3 from '../../assets/yanxuan/qiyejiagou/chanyeDetail3.png';
import chanyeDetail4 from '../../assets/yanxuan/qiyejiagou/chanyeDetail4.png';
import chanyeDetail5 from '../../assets/yanxuan/qiyejiagou/chanyeDetail5.png';
import chanyeDetail6 from '../../assets/yanxuan/qiyejiagou/chanyeDetail6.png';
// import AiAssistant from "../../components/aiAssistant";

import BottomView from "../../components/bottomView";
import ToTop from "../../components/toTop";

const Structure = () => {
  const [activeTab, setActiveTab] = useState('1');
  return <div className="platForm">
    <HeaderFFF index='platForm' bc='#fff' />
    <img src={mainImg} style={{ width: '100%' }} />
    <div className="mainContentDiv">
      <div className="top">
        <div className="text1">企业架构</div>
        <div className="text2">药都严选健康产业集团是一家综合性产业集团创新型企业，涵盖了国医诊疗、产品研发、品牌连锁、电商销售和教育培训等多个领域。集团包括药都严选健康科技有限公司、药都直播基地有限公司、药都严选商贸有限公司、药都严选国医馆有限公司、药都严选现代化农业发展产业有限公司、药都严选大健康产业研究院等公司与机构，及中医药传承创新基地、药都严选智慧中药方房两家中医药行业专业机构，同时药都严选在北京区域、石家庄区域、保定区域设有三家分公司。药都j选健康产业集团担任安国药膳产业联盟理事长,安国药食同源产业协会会长单位,安国中医药协会副会长单位,入选保定市药膳重点企业名单。</div>
        {/* <div className="text2">药都严选，严选全国，为国人健康服务</div> */}
        <div className="bottomLine"></div>
      </div>
      <img src={title1Img} className="title1Img"  />
      <div className="tabCon">
        <div onClick={() => { setActiveTab('1') }} className={activeTab == '1' ? "chanyeConActive" : "chanyeCon"}>
          <img className="chanyeConImg" src={chanye1Img} />
          <div className="chanyeContext">药都直播基地</div>
          <div className="chanyeConline"></div>
        </div>
        <div onClick={() => { setActiveTab('2') }} className={activeTab == '2' ? "chanyeConActive" : "chanyeCon"}>
          <img className="chanyeConImg" src={chanye2Img} />
          <div className="chanyeContext">
            <div className="text1">药都严选</div>
            <div className="text2">大健康产业研究院</div>
          </div>
          <div className="chanyeConline"></div>
        </div>
        <div onClick={() => { setActiveTab('3') }} className={activeTab == '3' ? "chanyeConActive" : "chanyeCon"}>
          <img className="chanyeConImg" src={chanye3Img} />
          <div className="chanyeContext">
            <div className="text1">药都严选</div>
            <div className="text2">健康科技有限公司</div></div>
          <div className="chanyeConline"></div>
        </div>
        <div onClick={() => { setActiveTab('4') }} className={activeTab == '4' ? "chanyeConActive" : "chanyeCon"}>
          <img className="chanyeConImg" src={chanye4Img} />
          <div className="chanyeContext">
            <div className="text1">药都严选</div>
            <div className="text2">商贸有限公司</div></div>
          <div className="chanyeConline"></div>
        </div>
        <div onClick={() => { setActiveTab('5') }} className={activeTab == '5' ? "chanyeConActive" : "chanyeCon"}>
          <img className="chanyeConImg" src={chanye5Img} />
          <div className="chanyeContext">
            <div className="text1">药都严选</div>
            <div className="text2">国医馆有限公司</div></div>
          <div className="chanyeConline"></div>
        </div>
        <div onClick={() => { setActiveTab('6') }} className={activeTab == '6' ? "chanyeConActive" : "chanyeCon"}>
          <img className="chanyeConImg" src={chanye6Img} />
          <div className="chanyeContext">
            <div className="text1">药都严选</div>
            <div className="text2">先到农业发展有限公司</div>
          </div>
          <div className="chanyeConline"></div>
        </div>
      </div>
      <div style={{marginBottom:50}}>
        {
          activeTab=='1'?<img src={chanyeDetail1} style={{width:'100%',marginTop:'20px'}}/>:null
        }
        {
          activeTab=='2'?<img src={chanyeDetail2} style={{width:'100%',marginTop:'10px'}}/>:null
        }
        {
          activeTab=='3'?<img src={chanyeDetail3} style={{width:'100%',marginTop:'20px'}}/>:null
        }
        {
          activeTab=='4'?<img src={chanyeDetail4} style={{width:'100%'}}/>:null
        }
        {
          activeTab=='5'?<img src={chanyeDetail5} style={{width:'100%',marginTop:'20px'}}/>:null
        }
        {
          activeTab=='6'?<img src={chanyeDetail6} style={{width:'100%'}}/>:null
        }
        {
          activeTab=='2'?<img src={chanyeDetail21} style={{width:'100%',marginTop:'30px'}}/>:null
        }
      </div>
    </div>
    {/* <AiAssistant/> */}
    <BottomView loca='platform' />
    <ToTop />
  </div>
}

export default Structure;