import React from "react";
import './index.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
// import './swiper.min.css'
import "swiper/css";
import "swiper/css/pagination";

const SwiperView = (props) => {
  const { imgList, imgH } = props;
  return <div className="swiperCon1">
    <Swiper
      modules={[Pagination, Scrollbar, Autoplay, Navigation, A11y]}
      pagination={false}
      spaceBetween={20}
      slidesPerView='4'
      // navigation={{ enabled: true }}
      // Pagination={{ enabled: true, clickable: true, el: '.swiper-pagination' }}
      // scrollbar={{ draggable: true }}
      // onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => { }}
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
      loop
    >
      {
        imgList.map((img, index) => {
          return <SwiperSlide key={index}><img src={img} className="rongyustayle" style={{ height: `${imgH}rem` }} /></SwiperSlide>
        })
      }
      <div className="btnCo">
        <div className="swiper-button-prev rot leftS" slot="button-prev">
          <img style={{width:'.5rem',height:'.5rem'}} src={require('../../assets/yanxuan/qiyegaikuang/left.png')} />
        </div>
        <div className="swiper-button-next rat" slot="button-next">
          <img style={{width:'.5rem',height:'.5rem'}} src={require('../../assets/yanxuan/qiyegaikuang/right.png')} />
        </div>
      </div>
    </Swiper>


  </div>
}

export default SwiperView;