import React, { useState, useEffect } from "react";
import './index.less';
import { useNavigate } from "react-router-dom";
import Header from "../../components/header";
import MainImg from '../../assets/yanxuan/qiyezixun/main.png';
import BottomView from "../../components/bottomView";

import ToTop from "../../components/toTop";
import { listCert } from './service';
import NewsItem from "./components/newsItem";
import NewsItemImg from "./components/newsItemImg";
// import AiAssistant from "../../components/aiAssistant";
const Message = () => {
  const navigate = useNavigate();
  const [dataList, setDataList] = useState([]);
  const [imgUrl, setImgUrl] = useState('');
  useEffect(() => {
    getList()
  }, [])
  const getList = () => {
    let data = {
      limit: 10,
      page: 1
    }
    listCert(data).then((res) => {
      if (res.result == 200) {
        setDataList(res.data.list)
        setImgUrl(res.data.url)
      }
    })
  }
  return <div className="aboutUs">
    <Header index='zixun' />
    <img src={MainImg} style={{ width: '100%' }} />
    <div className="mainCOn">
      <div className="top1">
        <div className="top11">企业资讯</div>
        <div className="top12">我们用心聆听城市的声音，让无数个希望可以扎根在这片土地，我们不断开拓创新，洞悉行业态势，我们怀揣使命与理想，积极寻求中医药文化的积淀。</div>
        <div className="top13"></div>
      </div>
      <div className="top2">
        <div className="left">
          {dataList.map((item, index) => {
            if (index < 2) {
              return <NewsItemImg data={item} key={index} iurl={imgUrl} />
            }
          })}
        </div>
        <div style={{ width: '1rem' }}></div>
        <div className="right">
          {dataList.map((item, index) => {
            if (index < 10) {
              return <NewsItem data={item} key={index} iurl={imgUrl} />
            }
          })}
        </div>
      </div>
    </div>
    {/* <AiAssistant/> */}
    <BottomView loca='About' />
    <ToTop red />
  </div>
}

export default Message;