import React, { useEffect, useState } from "react";
import './index.less';
import Header from "../../components/header";
import BottomView from '../../components/bottomView';
import CertItem from "./certItem";
import { listCert } from './service';
import { Button, message } from "antd";
import EmptyIcon from '../../assets/yanxuan/cert/empty.png';
// import AiAssistant from "../../components/aiAssistant";
const Cert = (props) => {
  const [keyword, setKeyWord] = useState('');
  const [dataList, setDataList] = useState([]);
  const [imgUrl, setImgUrl] = useState('');
  const changeKey = (val) => {
    console.log(val.target.value)
    setKeyWord(val.target.value)
  }
  const getList = () => {
    if(keyword==''){
      message.error('请输入持有人姓名')
      return
    }
    let data = {
      keyword: keyword,
      limit: 10,
      page: 1
    }
    listCert(data).then((res) => {
      if (res.result == 200) {
        setDataList(res.data.list)
        setImgUrl(res.data.url)
      }
    })
  }
  return <div className="cert">
    <Header index='cert' bc='#fff' />
    <div className="searchblock" >
      <div className="cyzs">查验证书</div>
      <div className="inputCon">
        <input onChange={changeKey} className="searchKey" placeholder="请输入要查询证书持有人的姓名" />
      </div>
      <div className="searchBtnCon">
        <Button block className="searchBtn" onClick={getList} >查询</Button>
      </div>

    </div>
    <div className="resultCon">
      <div className="resultTop">
        <div className="cxjg">查询结果</div>
        <div className="engLishTi">QUERY RESULT</div>
        <div className="redLine"></div>
      </div>
      <div className="resultList">
        {
          dataList.length > 0 ? <div>
            {
              dataList.map((item, index) => {
                return <CertItem theUrl={imgUrl} data={item} key={index} />
              })
            }
          </div> : <div className="emptyCon">
            <img src={EmptyIcon} className="emptyIcon" />
            <div className="kong">查询结果为空</div>
          </div>
        }
      </div>
    </div>
    {/* <AiAssistant/> */}
    <BottomView loca='cert' />
  </div>
}

export default Cert;