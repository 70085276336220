import React, { useState, useEffect } from "react";
import './index.css';
import ReactPlayer from "react-player";
import PlayBtn from '../../assets/play.webp';
import UnPlay from '../../assets/unPlay.webp';

const VideoPlayer = (props) => {
  const { videoSource, toPlay } = props;
  const [isPlay, setIsPlay] = useState(true);
  const [showB, setShowB] = useState(true);
  useEffect(() => {
    setIsPlay(toPlay)
  }, [toPlay])
  return <div className='videoCon' onMouseOver={() => { setShowB(true); }} onMouseLeave={() => { setShowB(false) }} >
      <ReactPlayer
        playing={isPlay}
        width='100%'
        height='100%'
        // height='10.22rem'
        url={videoSource}
        muted
        loop
        playsinline
        // fullScreen={false}
      />
    {
      showB || !isPlay ? <div className="playB">{isPlay ? <img className="btnI" onClick={() => { setIsPlay(false) }} src={UnPlay} /> : <img className="btnI" onClick={() => { setIsPlay(true) }} src={PlayBtn} />}</div> : null
    }
  </div>
}
export default VideoPlayer;