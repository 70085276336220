import './App.css';
import { Route, Routes, BrowserRouter, HashRouter } from "react-router-dom";
import AboutAg from './pages/aboutAg';//千年药都
import AboutCorporation from "./pages/aboutCorporation";//企业概况
import Structure from './pages/structure'; //企业架构
import Business from './pages/business';//关于我们
import Contact from "./pages/contact";//业务范围
import Cert from "./pages/cert";//证书查询
import AboutBase from "./pages/aboutBase";//创新基地
import ProductCenter from "./pages/productCenter";//产品中心
import Message from "./pages/message";//企业资讯
import MessageDetail from "./pages/messageDetail";//资讯详情
import ThirdF from "./pages/thirdF";//3层
import SecondF from "./pages/secondF";//2层
import FirstF from "./pages/firstF";//1层
function App() {
  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route path='/aboutAg' exact element={<AboutAg/>} ></Route>
          <Route path='/' exact element={<AboutCorporation/>} ></Route>
          <Route path='/structure' exact element={<Structure/>} ></Route>
          <Route path='/aboutBase' exact element={<AboutBase/>} ></Route>
          <Route path='/productCenter' exact element={<ProductCenter/>} ></Route>
          <Route path='/message' exact element={<Message/>} ></Route>
          <Route path='/messageDetail' exact element={<MessageDetail/>} ></Route>

          <Route path='/business' exact element={<Business/>} ></Route>
          <Route path='/contact' exact element={<Contact/>} ></Route>
          <Route path='/cert' exact element={<Cert/>} ></Route>

          <Route path='/firstF' exact element={<FirstF/>} ></Route>
          <Route path='/secondF' exact element={<SecondF/>} ></Route>
          <Route path='/thirdF' exact element={<ThirdF/>} ></Route>
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
