import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './index.less';
import Header from "../../components/header";
import SwiperView from "../../components/swiper";
import SwiperView1 from "./components/swiper1";
import mainImage1 from '../../assets/yanxuan/chuangxinjidi/mainImage1.png';
import jidi1 from '../../assets/yanxuan/chuangxinjidi/jidi1.png';
import jidi2 from '../../assets/yanxuan/chuangxinjidi/jidi2.png';
import jidi3 from '../../assets/yanxuan/chuangxinjidi/jidi3.png';
import L1 from '../../assets/yanxuan/chuangxinjidi/l1.png';
import L2 from '../../assets/yanxuan/chuangxinjidi/l2.png';
import L3 from '../../assets/yanxuan/chuangxinjidi/l3.png';
import L4 from '../../assets/yanxuan/chuangxinjidi/l4.png';
import L5 from '../../assets/yanxuan/chuangxinjidi/l5.png';
import L6 from '../../assets/yanxuan/chuangxinjidi/l6.png';
import L7 from '../../assets/yanxuan/chuangxinjidi/l7.png';
import L8 from '../../assets/yanxuan/chuangxinjidi/l8.png';
import L9 from '../../assets/yanxuan/chuangxinjidi/l9.png';
import yilou from '../../assets/yanxuan/chuangxinjidi/yilou.png';
import erlou from '../../assets/yanxuan/chuangxinjidi/erlou.png';
import sanlou from '../../assets/yanxuan/chuangxinjidi/sanlou.png';
import BottomView from "../../components/bottomView";
import checkMoreBtn from '../../assets/yanxuan/chuangxinjidi/checkMoreBtn.png'
import ToTop from "../../components/toTop";
import { listCert } from './service';
import NewsItem from "./components/newsItem";
import VideoPlayer from "../../components/player";
import chanyeDetail21 from '../../assets/yanxuan/qiyejiagou/changyeDetail21.png';
// import AiAssistant from "../../components/aiAssistant";
const AboutBase = () => {
  // 中医药传承创新基地
  const [playVideo, setPlayVideo] = useState(false);
  const navigate = useNavigate();
  const [active, setActive] = useState('0');
  const [dataList, setDataList] = useState([]);
  const [imgUrl, setImgUrl] = useState('');
  const changeActive = (i) => {
    if (active == i) {
      setActive('0')
    } else {
      setActive(i)
    }
  }
  useEffect(() => {
    getList()
  }, [])
  const getList = () => {
    let data = {
      limit: 10,
      page: 1
    }
    listCert(data).then((res) => {
      if (res.result == 200) {
        setDataList(res.data.list)
        console.log(res.data.list[0])
        setImgUrl(res.data.url)
      }
    })
  }
  return <div className="Digital" style={{ backgroudColor: '#fff' }}>
    <Header index='digital' bc='#fff' />
    <SwiperView imgList={[jidi1, jidi2, jidi3]} imgH={7.99} />
    <div className="videotext">
 {/* //todo:此处为外部视频链接 */}
      <VideoPlayer toPlay={playVideo} videoSource='https://www.yaoduyanxuan.com.cn/webvideo/static/media/qihaoguan.852234d7429075b4be7d.mp4' />

      {/* <img src={jidi4} style={{ width: '100%' }} /> */}
      <div className="textCOn">
        <div className="text11">
          传承千年药都精神，创新中式养生内涵。党的十八大以来，习近平总书记高度重视中医药工作，强调“传承精华，守正创新”，并指出“加快推进健康中国建设，努力全方位、全周期保障人民健康”，为新时代中医药发展指明了前进方向。药都严选健康产业集团积极响应国家中医药发展战略，深挖千年药都文化资源，联手中国中医科学院、北京联合大学等顶级专家及行业机构，在安国市委市政府大力支持下，成立“药都严选中医药传承创新基地”。
        </div>
        <div className="text11">
          药都严选中医药传承创新基地（7号馆）作为园区入驻项目之一，占地面积近4000平方米，集中医药传承教育、中医药创新产品体验、国医特色诊疗、中医药非遗技艺传承、中医药学术交流五大业态于一体，涵盖了传承书院、养生产品、本草茶饮、体质调养、国医诊疗、中医适宜技术、中药炮制非遗技艺等多功能板块。
        </div>
        <div className="text11">
          一层：百草厅、传承书院、养生阁、饮膳阁、中医阁

        </div>
        <div className="text11">
          二层：中医适宜技术传承中心

        </div>
        <div className="text11">
          三层：中药古法炮制传承中心

        </div>
      </div>


    </div>


    <img src={mainImage1} style={{ width: '100%' }} />
    <div className="imageHovorList">
      <div className="imageHovor1"></div>
      <div className="imageHovor2" ></div>
      <div className="imageHovor3" ></div>
      <div className="imageHovor4" ></div>
      <div className="imageHovor5" ></div>
      <div className="imageHovor6" ></div>
      <div className="imageHovor7" ></div>
      <div className="imageHovor8" ></div>
    </div>
    <div className="loucengCon">
      <SwiperView1 imgList={[L4, L5, L1, L2, L3, L6, L7, L8, L9]} imgH={8.99} />
      {
        active === '1' || active === '2' || active === '3' ? <div className="consk">
          {
            active == '1' ? <img src={yilou} onClick={() => { navigate('/firstF') }} style={{ width: '100%', height: '8.99rem' }} /> : null
          }
          {
            active == '2' ? <img src={erlou} onClick={() => { navigate('/secondF') }} style={{ width: '100%', height: '8.99rem' }} /> : null
          }
          {
            active == '3' ? <img src={sanlou} onClick={() => { navigate('/thirdF') }} style={{ width: '100%', height: '8.99rem' }} /> : null
          }

        </div> : null
      }

    </div>
    <div className="loucengBtnCon">
      <div className={active == '1' ? 'loucengBtnActive' : 'loucengBtn'} onClick={() => { changeActive('1') }} >1F位置分布及功能介绍</div>
      <div className={active == '2' ? 'loucengBtnActive' : 'loucengBtn'} onClick={() => { changeActive('2') }} style={{ borderLeftWidth: 1, borderLeftColor: '#00000050', borderLeftStyle: 'solid', borderRightWidth: 1, borderRightColor: '#00000050', borderRightStyle: 'solid' }}>2F位置分布及功能介绍</div>
      <div className={active == '3' ? 'loucengBtnActive' : 'loucengBtn'} onClick={() => { changeActive('3') }}>3F位置分布及功能介绍</div>
    </div>
    <div className="jdrxT">
      <div className="jdrx">基地热讯</div>
      <div className="news">NEWS</div>
      <div className="lineA"></div>
    </div>
    <div className="newCo">
      {
        dataList.length > 0 ? <img src={`${imgUrl}${dataList[0].image}`} style={{ width: '43%' }} /> : null
      }

      <div style={{ width: '8%' }}></div>
      <div className="NewsCon">
        <div className="newsListCon">
          {
            dataList.map((item, index) => {
              if (index < 3) {
                return <NewsItem key={index} data={item} />
              }
            })
          }
        </div>
      </div>
    </div>
    <div className="checkMore">
      <img className="checkMoreBtn" onClick={() => { navigate('/message') }} src={checkMoreBtn} />
    </div>
    <img src={chanyeDetail21} style={{ width: '100%', paddingLeft: '1.8rem', paddingRight: '1.8rem', marginTop: '30px', marginBottom: '30px' }} />
    {/* <AiAssistant/> */}
    <BottomView loca='virtual' />
    <ToTop red />
  </div>
}








export default AboutBase;







